
import {Options as Component, Vue} from "vue-class-component";
import {menuList} from "@/functions/MenuList";
import PageTitle from "@/components/PageTitle.vue";
import LLines from "@/components/LLines.vue";

@Component({
  name: "About",
  components: {LLines, PageTitle},
})
export default class About extends Vue {
  get color() {
    return menuList.find(el => el.id === "about")?.bg_color;
  }

  get anniEsperienza() {
    return new Date().getFullYear() - new Date("2009-01-01").getFullYear()
  }
}
