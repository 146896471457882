
import {Options as Component, Vue} from "vue-class-component";
import {menuList} from "@/functions/MenuList";
import PageTitle from "@/components/PageTitle.vue";
import FormInput from "@/components/FormInput.vue";
import Button from "@/components/Button.vue";
import EmailJs from "emailjs-com";
import {validate} from "validate.js"
import {Watch} from "vue-property-decorator";

@Component({
  name: "Contact",
  components: {Button, FormInput, PageTitle},
})
export default class Contact extends Vue {
  emailJsSettings = {
    serviceID: "default_service",
    templateID: "template_vcmic8r",
    userID: "user_JrieOJb0UAy9jlczFhnHa"
  }
  loading: boolean = false;
  emailSent: boolean = false;
  emailError: boolean = false;

  formErrors: any = {}

  validations = {
    name: {
      presence: true,
      length: {
        minimum: 4,
        message: "must be at least 6 characters"
      }
    },
    email: {
      presence: true,
      email: true
    },
    message: {
      presence: true,
      length: {
        minimum: 10,
      }
    }
  }

  formData = {
    name: "",
    email: "",
    message: ""
  }

  get color() {
    return menuList.find(el => el.id === "contact")?.bg_color;
  }

  get errors() {
    return this.formErrors ?? {}
  }

  validate() {
    this.formErrors = validate(this.formData, this.validations);
  }

  async sendEmail() {
    this.validate();

    if (this.formErrors && Object.keys(this.formErrors).length > 0) {
      return;
    }

    this.loading = true

    try {
      const emailData = {
        from_name: this.formData.name,
        from_email: this.formData.email,
        from_date: new Date().toLocaleString(),
        message: this.formData.message
      }

      await EmailJs.send(
          this.emailJsSettings.serviceID,
          this.emailJsSettings.templateID,
          emailData,
          this.emailJsSettings.userID)

      this.emailSent = true
    } catch (er) {
      console.error(er)
      this.emailError = true
    } finally {
      this.loading = false
    }
  }

  @Watch("formData", {deep: true})
  onFormDataChange() {
    this.validate();
  }
}
