
import {Options as Component, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator"


@Component({name: "PageTitle"})
export default class PageTitle extends Vue {
  @Prop({type: String})
  identifier!: string
}
