
import {defineComponent} from 'vue'
import anime from 'animejs/lib/anime';

export default defineComponent({
  name: "MainLogo",
  props: {
    showExpandableText: {
      type: Boolean,
      default: true
    },
    textDelay: {
      type: Number,
      default: 4000
    }
  },
  data() {
    return {
      text: [
        {
          initial: "L",
          text: "Leica"
        }, {
          initial: "F",
          text: "Florian"
        }, {
          initial: "R",
          text: "Robert"
        }
      ]
    }
  },
  methods: {
    intiLogoAnimation() {
      const initialDelay = 2000;

      const timeline = anime.timeline({
        easing: 'easeInOutSine',
        duration: 4000,
        complete: () => {
          this.$emit("animation:completed")
        }
      });

      // letter L
      timeline.add({
        targets: '.logo-letter-l',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: initialDelay,
      });

      timeline.add({
        targets: '.logo-letter-l-overlay',
        strokeDashoffset: [anime.setDashoffset, 0],
      }, initialDelay + 1000);

      // Letter F
      timeline.add({
        targets: '.logo-letter-f',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: anime.stagger(1000),
      }, 2000);

      // letter R
      timeline.add({
        targets: '.logo-letter-r',
        // strokeWidth: 0.11,
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: anime.stagger(1000),
      }, 2000);

      // Animation for light tracking on stroke path
      /*    var path = anime.path('.logo-letter-l');

          anime({
            targets: '#light',
            translateX: path('x'),
            translateY: path('y'),
            rotate: path('angle'),
            easing: 'linear',
            duration: 3000,
            loop: true
          });*/
    },

    initTextExpansion() {
      // I'm using a timeout to wait for the dom to render and calculate correctly the with for the collapsed text.
      setTimeout(() => {
        const timeline = anime.timeline({
          delay: 4000,
          complete() {
            document.querySelectorAll(".text-letter .text-to-expand")
                .forEach((el: any) => {
                  el.style.width = "auto"
                })
          }
        })

        timeline.add({
          targets: ".text-letter:nth-child(1)",
          translateX: ["-400%", 0],
          opacity: [0, 1],
          easing: "easeInOutBack",
          duration: 2000,
          delay: this.textDelay
        }, 0)

        timeline.add({
          targets: ".text-letter:nth-child(2)",
          translateY: ["200%", 0],
          opacity: [0, 1],
          easing: "easeInOutBack",
          duration: 1500,
          delay: this.textDelay
        }, 0)

        timeline.add({
          targets: ".text-letter:nth-child(3)",
          translateX: ["400%", 0],
          opacity: [0, 1],
          easing: "easeInOutBack",
          duration: 2000,
          delay: this.textDelay
        }, 0)

        /*timeline.add({
          targets: filter,
          blur: -1,
          round: 1,
          update: function (test: any, b: any) {
            console.log(b)
            if (filter.blur > 0) {
              //letters.style.filter = `blur(${filter.blur}px)`
            } else {
              //letters.style.filter = ``
            }
          }
        })*/

        timeline.add({
          targets: ".text-letter .text-to-expand",
          opacity: [0, 1],
          easing: "easeOutElastic",
          duration: 1500,
          width: (el: HTMLElement) => {
            /*console.log(el.scrollWidth)*/
            return el.scrollWidth
          },
          delay: anime.stagger(100, {start: 500})
        })
      }, 100)
    }
  },

  mounted() {
    this.intiLogoAnimation()

    if (this.showExpandableText) {
      this.initTextExpansion()
    }
  }
})
