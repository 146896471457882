
import { defineComponent, PropType } from 'vue'

export interface ProjectProps {
  title: string
  link?: string
  description: string
  image?: string,
  github?: string,
}

export default defineComponent({
  name: 'Project',
  props: {
    project: {
      type: Object as PropType<ProjectProps>,
      required: true
    }
  }
})
