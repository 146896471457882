
import {Options as Component, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import LLines from "@/components/LLines.vue";

@Component({
  components: {LLines},
})
export default class FeatureBox extends Vue {
  @Prop({type: String})
  icon!: string

  @Prop({type: String})
  title!: string

  @Prop({type: String})
  text!: string
}
