
import {Options as Component, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
  name: "WorkExperience",
  components: {},
})
export default class WorkExperience extends Vue {
  @Prop({type: Number})
  index!: number

  @Prop({type: String})
  date!: string

  @Prop({type: String, default: "Titolo"})
  title!: string

  @Prop({type: String, default: "Sottotitolo"})
  subtitle!: string

  @Prop({
    type: String,
    default: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet dolorum ducimus ex exercitationem expedita facere, nisi quas quod tempore unde veniam veritatis vero! Excepturi illo, itaque molestias quia sed voluptatibus."
  })
  content!: string

  get odd() {
    return this.index % 2 === 0
  }
}
