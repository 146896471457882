export const menuList = [
    {
        id: "about",
        link: "about",
        bg_color: "#f8f9fa"
    }, {
        id: "services",
        link: "services",
        bg_color: "#939597"
    }, {
        id: "curriculum",
        link: "curriculum",
        bg_color: "#596771"
    }, {
        id: "contact",
        link: "contact",
        bg_color: "#3c6377"
    }
]
