
import {Options as Component, Vue} from "vue-class-component";
import {menuList} from "@/functions/MenuList";
import PageTitle from "@/components/PageTitle.vue";
import FeatureBox from "@/components/FeatureBox.vue";

@Component({
  name: "Services",
  components: {FeatureBox, PageTitle},
})
export default class Services extends Vue {
  get color() {
    return menuList.find(el => el.id === "services")?.bg_color;
  }
}
