
import {Options as Component, Vue} from "vue-class-component";
import LLines from "@/components/LLines.vue";
import MainLogo from "@/components/MainLogo.vue";
import anime from "animejs/lib/anime";
import VanillaTilt from "vanilla-tilt";

@Component({
  name: "Home",
  components: {
    LLines, MainLogo
  },
})
export default class Home extends Vue {

  $refs!: {
    mainLogo: typeof MainLogo
  }

  animateScrollDownArrow() {
    const initialDelay = 6000;

    const tl = anime.timeline({
      easing: "easeInOutExpo",

      delay: initialDelay
    })

    tl.add({
      targets: ".scroll-down-wrapper .scroll-down-line",
      height: [0, "88%"]
    })

    tl.add({
      targets: ".scroll-down-wrapper .scroll-down-icon",
      bottom: [0, "90%"],
      translateY: ["100%", "0px"],
      easing: "easeOutElastic",
      complete() {
        const target = this.animatables[0].target;

        anime({
          targets: target,
          duration: 1000,
          easing: "linear",
          translateY: [0, 10, -10, 10, -10, 0],
          loop: true,
          delay: 5000
        })
      }
    }, "-=" + (initialDelay + 500))

    tl.add({
      targets: ".scroll-down-wrapper .scroll-down-text-span",
      opacity: [0, 1],
      translateX: ["-100%", "0"],
      easing: "easeOutElastic",
      duration: 1000,
      complete() {
        const target = this.animatables[0].target.parentNode;

        anime({
          targets: target,
          duration: 1000,
          easing: "linear",
          translateY: [0, 10, -10, 10, -10, 10, -10, 0],
          loop: true,
          delay: 7000
        })
      }
    }, "-=" + (initialDelay))
  }

  mounted() {
    this.animateScrollDownArrow()

    VanillaTilt.init(document.querySelector(".main-logo-tilt") as HTMLElement, {

    })

    VanillaTilt.init(document.querySelector(".scroll-down-wrapper") as HTMLElement, {

    })
  }
}
