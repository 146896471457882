
import {Options as Component, Vue} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";

import anime from "animejs/lib/anime";

@Component({
  name: "FormInput",
  components: {},
})
export default class FormInput extends Vue {
  @Prop({type: String, default: "text"})
  type!: string

  @Prop({type: String})
  name!: string

  @Prop({type: String})
  label!: string

  @Prop({type: String})
  placeholder!: string

  @Prop({type: String})
  icon!: string

  @Prop({type: String})
  modelValue!: string

  @Prop({type: Boolean})
  inputGroup!: boolean

  @Prop({type: Boolean})
  required!: boolean

  @Prop({type: Array})
  error!: string[]

  inputValue: string = this.modelValue ?? ""

  $refs!: {
    input_group: HTMLElement
    formInput: HTMLElement & { startAnimation: () => void }
  }

  get placeholderEscaped() {
    return this.placeholder;
  }

  onFocusIn() {
    this.$refs.input_group.classList.add("focus")
  }

  onFocusOut() {
    if (!this.inputValue) {
      this.$refs.input_group.classList.remove("focus")
    }
  }

  @Watch("inputValue")
  onValueChange(value: string) {
    this.$emit("update:modelValue", value)
  }

  startAnimation() {
    const tl = anime.timeline({
      easing: "easeOutExpo",
      duration: 1500
    })

    tl.add({
      targets: this.$refs.formInput.querySelector(".l-line-left"),
      right: 0,
      width: 60
    }, 200)

    tl.add({
      targets: this.$refs.formInput.querySelector(".l-line-right"),
      width: 60
    }, 200)
  }

  mounted() {
    this.$refs.formInput.startAnimation = this.startAnimation
  }
}
