<template>
  <svg class="main-logo" viewBox="0 0 3000 2000" xmlns="http://www.w3.org/2000/svg"
       style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-14195.8,-4689.54)">
      <g id="Logo-Bianco-SENZA-testo" transform="matrix(1.45453,0,0,1,9506.16,4189.54)">
        <rect x="3224.18" y="0" width="2062.53" height="3000" style="fill:none;"/>
        <g transform="matrix(1069.19,0,0,1558.67,4465.64,2044.93)">
          <path class="svg-path logo-letter-r" mask="url(#letterR)"
                d="M0,0.012L0.076,0.012L0.076,-0.636L0.235,-0.636C0.27,-0.636 0.305,-0.628 0.338,-0.611C0.402,-0.58 0.434,-0.525 0.434,-0.446C0.434,-0.368 0.402,-0.313 0.338,-0.281C0.305,-0.265 0.27,-0.256 0.235,-0.256L0.104,-0.256L0.177,-0.185L0.38,0.012L0.491,0.012L0.286,-0.186C0.327,-0.191 0.369,-0.208 0.411,-0.237C0.477,-0.284 0.511,-0.354 0.511,-0.446C0.511,-0.556 0.465,-0.633 0.373,-0.677C0.327,-0.7 0.281,-0.711 0.235,-0.711L0,-0.711L0,0.012Z"
                style="stroke: rgba(0,0,0,.5); stroke-width:0.02px"/>

          <path class="svg-path logo-letter-r" mask="url(#letterR)"
                d="M0,0.012L0.076,0.012L0.076,-0.636L0.235,-0.636C0.27,-0.636 0.305,-0.628 0.338,-0.611C0.402,-0.58 0.434,-0.525 0.434,-0.446C0.434,-0.368 0.402,-0.313 0.338,-0.281C0.305,-0.265 0.27,-0.256 0.235,-0.256L0.104,-0.256L0.177,-0.185L0.38,0.012L0.491,0.012L0.286,-0.186C0.327,-0.191 0.369,-0.208 0.411,-0.237C0.477,-0.284 0.511,-0.354 0.511,-0.446C0.511,-0.556 0.465,-0.633 0.373,-0.677C0.327,-0.7 0.281,-0.711 0.235,-0.711L0,-0.711L0,0.012Z"
                style="stroke: white; stroke-width:0.11px"/>
        </g>
        <g>
          <g transform="matrix(0.687508,0,0,1,3224.79,83.9865)">
            <path class="svg-path logo-letter-l"
                  d="M2748.23,490.101L2748.23,625.789L1477.81,625.789L1477.81,2206.24L1349.5,2206.24L1342.53,490.101L2748.23,490.101Z"
                  style="stroke: rgba(0,0,0,.5); stroke-width:1%" mask="url(#letterL1)"/>
            <path class="svg-path logo-letter-l-overlay"
                  d="M2748.23,490.101L2748.23,625.789L1477.81,625.789L1477.81,2206.24L1349.5,2206.24L1342.53,490.101L2748.23,490.101Z"
                  style="stroke: white; stroke-width:6%" mask="url(#letterL1)"/>

          </g>
          <g transform="matrix(0.687508,0,0,1,3224.79,83.9865)">
            <path class="svg-path logo-letter-l"
                  d="M250,2341.93L250,2206.24L1520.42,2206.24L1520.42,625.789L1655.71,625.789L1655.71,2341.93L250,2341.93Z"
                  style="stroke: rgba(0,0,0,.5); stroke-width:1%" mask="url(#letterL2)"/>
            <path class="svg-path logo-letter-l-overlay"
                  d="M250,2341.93L250,2206.24L1520.42,2206.24L1520.42,625.789L1655.71,625.789L1655.71,2341.93L250,2341.93Z"
                  style="stroke: white; stroke-width:6%" mask="url(#letterL2)"/>
          </g>
        </g>
        <g transform="matrix(1033.6,0,0,1506.78,3499.16,2027.89)">
          <path class="svg-path logo-letter-f" mask="url(#letterF)"
                d="M0.134,-0.187L0.134,-0.263L0.528,-0.263L0.528,-0.187L0.134,-0.187ZM0,0.012L0.078,0.012L0.078,-0.636L0.528,-0.636L0.528,-0.713L0,-0.713L0,0.012Z"
                style="stroke: rgba(0,0,0,.5); stroke-width:0.02px"/>
          <path class="svg-path logo-letter-f" mask="url(#letterF)"
                d="M0.134,-0.187L0.134,-0.263L0.528,-0.263L0.528,-0.187L0.134,-0.187ZM0,0.012L0.078,0.012L0.078,-0.636L0.528,-0.636L0.528,-0.713L0,-0.713L0,0.012Z"
                style="stroke: white"/>
        </g>
      </g>
    </g>

    <defs>
      <mask id="letterF">
        <path style="fill:white;fill-rule:nonzero;"
              d="M0.134,-0.187L0.134,-0.263L0.528,-0.263L0.528,-0.187L0.134,-0.187ZM0,0.012L0.078,0.012L0.078,-0.636L0.528,-0.636L0.528,-0.713L0,-0.713L0,0.012Z"/>
      </mask>

      <mask id="letterR">
        <path style="fill:white;fill-rule:nonzero;"
              d="M0,0.012L0.076,0.012L0.076,-0.636L0.235,-0.636C0.27,-0.636 0.305,-0.628 0.338,-0.611C0.402,-0.58 0.434,-0.525 0.434,-0.446C0.434,-0.368 0.402,-0.313 0.338,-0.281C0.305,-0.265 0.27,-0.256 0.235,-0.256L0.104,-0.256L0.177,-0.185L0.38,0.012L0.491,0.012L0.286,-0.186C0.327,-0.191 0.369,-0.208 0.411,-0.237C0.477,-0.284 0.511,-0.354 0.511,-0.446C0.511,-0.556 0.465,-0.633 0.373,-0.677C0.327,-0.7 0.281,-0.711 0.235,-0.711L0,-0.711L0,0.012Z"/>
      </mask>

      <mask id="letterL1">
        <path
            d="M2748.23,490.101L2748.23,625.789L1477.81,625.789L1477.81,2206.24L1349.5,2206.24L1342.53,490.101L2748.23,490.101Z"
            style="fill:white;fill-rule:nonzero;"/>
      </mask>
      <mask id="letterL2">
        <path d="M250,2341.93L250,2206.24L1520.42,2206.24L1520.42,625.789L1655.71,625.789L1655.71,2341.93L250,2341.93Z"
              style="fill:white;fill-rule:nonzero;"/>
      </mask>
    </defs>
  </svg>

  <div class="text-initials-expand-container">
    <span class="text-letter" v-for="block in text" :key="block.initial">
      {{ block.initial }}<span class="text-to-expand">{{ block.text.slice(1) }}</span>
    </span>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import anime from 'animejs/lib/anime';

export default defineComponent({
  name: "MainLogo",
  props: {
    showExpandableText: {
      type: Boolean,
      default: true
    },
    textDelay: {
      type: Number,
      default: 4000
    }
  },
  data() {
    return {
      text: [
        {
          initial: "L",
          text: "Leica"
        }, {
          initial: "F",
          text: "Florian"
        }, {
          initial: "R",
          text: "Robert"
        }
      ]
    }
  },
  methods: {
    intiLogoAnimation() {
      const initialDelay = 2000;

      const timeline = anime.timeline({
        easing: 'easeInOutSine',
        duration: 4000,
        complete: () => {
          this.$emit("animation:completed")
        }
      });

      // letter L
      timeline.add({
        targets: '.logo-letter-l',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: initialDelay,
      });

      timeline.add({
        targets: '.logo-letter-l-overlay',
        strokeDashoffset: [anime.setDashoffset, 0],
      }, initialDelay + 1000);

      // Letter F
      timeline.add({
        targets: '.logo-letter-f',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: anime.stagger(1000),
      }, 2000);

      // letter R
      timeline.add({
        targets: '.logo-letter-r',
        // strokeWidth: 0.11,
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: anime.stagger(1000),
      }, 2000);

      // Animation for light tracking on stroke path
      /*    var path = anime.path('.logo-letter-l');

          anime({
            targets: '#light',
            translateX: path('x'),
            translateY: path('y'),
            rotate: path('angle'),
            easing: 'linear',
            duration: 3000,
            loop: true
          });*/
    },

    initTextExpansion() {
      // I'm using a timeout to wait for the dom to render and calculate correctly the with for the collapsed text.
      setTimeout(() => {
        const timeline = anime.timeline({
          delay: 4000,
          complete() {
            document.querySelectorAll(".text-letter .text-to-expand")
                .forEach((el: any) => {
                  el.style.width = "auto"
                })
          }
        })

        timeline.add({
          targets: ".text-letter:nth-child(1)",
          translateX: ["-400%", 0],
          opacity: [0, 1],
          easing: "easeInOutBack",
          duration: 2000,
          delay: this.textDelay
        }, 0)

        timeline.add({
          targets: ".text-letter:nth-child(2)",
          translateY: ["200%", 0],
          opacity: [0, 1],
          easing: "easeInOutBack",
          duration: 1500,
          delay: this.textDelay
        }, 0)

        timeline.add({
          targets: ".text-letter:nth-child(3)",
          translateX: ["400%", 0],
          opacity: [0, 1],
          easing: "easeInOutBack",
          duration: 2000,
          delay: this.textDelay
        }, 0)

        /*timeline.add({
          targets: filter,
          blur: -1,
          round: 1,
          update: function (test: any, b: any) {
            console.log(b)
            if (filter.blur > 0) {
              //letters.style.filter = `blur(${filter.blur}px)`
            } else {
              //letters.style.filter = ``
            }
          }
        })*/

        timeline.add({
          targets: ".text-letter .text-to-expand",
          opacity: [0, 1],
          easing: "easeOutElastic",
          duration: 1500,
          width: (el: HTMLElement) => {
            /*console.log(el.scrollWidth)*/
            return el.scrollWidth
          },
          delay: anime.stagger(100, {start: 500})
        })
      }, 100)
    }
  },

  mounted() {
    this.intiLogoAnimation()

    if (this.showExpandableText) {
      this.initTextExpansion()
    }
  }
})
</script>

<style scoped lang="scss">


</style>
